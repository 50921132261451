import { useState, useEffect } from 'react'
import {
    PaperClipIcon,
    UserCircleIcon,
    ArrowLongLeftIcon,
    XMarkIcon as XMarkIconMini,
} from '@heroicons/react/20/solid'
import { db, auth, storage, functions } from '../firebase';
import { doc, onSnapshot, updateDoc, arrayUnion } from "firebase/firestore";
import { ref, listAll, getMetadata, getDownloadURL, deleteObject } from "firebase/storage";
import { httpsCallable } from "firebase/functions";
import { CheckCircleIcon, UserIcon } from '@heroicons/react/24/solid'
import { NavLink, useParams } from 'react-router-dom'
import DeleteClientDialog from './deleteClient';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import UploadDocumentsDialog from './uploadNewDocuments';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

function formatFileSize(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Client() {
    let { clientId } = useParams();
    const [client, setClient] = useState(false)
    const [files, setFiles] = useState([])
    const [deleteClientOpened, setDeleteClientOpened] = useState(false)
    const [message, setMessage] = useState("")
    const [messageTarget, setMessageTarget] = useState("")
    const [uploadOpened, setUploadOpened] = useState(false)
    const [saveText, setSaveText] = useState("Save")
    const [submitByAccuviseLoading, setSubmitByAccuviseLoading] = useState(false)
    const [loadingDocuments, setLoadingDocuments] = useState(false)
    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "clients", clientId), (doc) => {
            setClient({ ...doc.data(), id: doc.id })
            getFiles();
        });
        return () => unsubscribe()
    }, [])
    const save = async (evt) => {
        evt.preventDefault()
        setSaveText("Successfully saved")
        await updateDoc(doc(db, "clients", clientId), client)
        setTimeout(() => setSaveText("Save"), 3000)
    }
    const getFiles = () => {
        setLoadingDocuments(true)
        listAll(ref(storage, '/' + clientId)).then(async (res) => {
            let items = []
            for (let i = 0; i < res.items.length; i++) {
                let itemRef = res.items[i]
                let { size, timeCreated } = await getMetadata(itemRef)
                let downloadURL = await getDownloadURL(itemRef)
                let uploadDate = new Date(timeCreated)
                items.push({
                    size: formatFileSize(size),
                    name: itemRef.name,
                    downloadURL,
                    uploadDate: uploadDate.toLocaleDateString("en-GB", {
                        day: '2-digit',
                        month: '2-digit',
                        year: '2-digit'
                    })
                })
            }
            setFiles(items)
            setLoadingDocuments(false)
        })

    }
    const downloadAllFiles = async () => {
        const zip = new JSZip();
        const folder = zip.folder("downloads");

        try {
            for (const file of files) {
                const response = await fetch(file.downloadURL);
                if (!response.ok) throw new Error('Network response was not ok');
                const blob = await response.blob();
                folder.file(file.name, blob, { binary: true });
            }

            zip.generateAsync({ type: "blob" }).then((content) => {
                saveAs(content, client.fullName + " documents.zip");
            });
        } catch (error) {
            console.error('There was an issue downloading the files: ', error);
        }
    };

    async function downloadFile(fileUrl, fileName) {
        try {
            // Fetch the file
            const response = await fetch(fileUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch ${fileUrl}: ${response.statusText}`);
            }
            // Retrieve the file as Blob
            const blob = await response.blob();
    
            // Create a temporary link element
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            
            // Append the link to the body
            document.body.appendChild(link);
            
            // Programmatically click the link to trigger the download
            link.click();
            
            // Remove the link from the body
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }
    
    const submitToAccuvise = async () => {
        await updateDoc(doc(db, "clients", clientId), { submittedToAccuvise: true, flaggedToAix: false })
    }
    const unsubmitFromAccuvise = async () => {
        await updateDoc(doc(db, "clients", clientId), { submittedToAccuvise: false })
    }
    const validateByAccuvise = async () => {
        setSubmitByAccuviseLoading(true)
        const onValidated = httpsCallable(functions, 'onValidated');
        let res = await onValidated({ clientId })
        console.log(res)
        setSubmitByAccuviseLoading(false)
    }
    const flagToAix = async () => {
        await updateDoc(doc(db, "clients", clientId), { flaggedToAix: true })
    }
    const unFlagToAix = async () => {
        await updateDoc(doc(db, "clients", clientId), { flaggedToAix: false })
    }
    const sendComment = async (evt) => {
        evt.preventDefault()
        let newActivity = {
            id: Date.now(),
            type: 'comment',
            message,
            date: Date.now(),
            target: auth.currentUser.uid == "AltaiGlobal" ? messageTarget : 'AltaiGlobal',
            author: auth.currentUser.uid
        }
        await updateDoc(doc(db, "clients", clientId), {
            activity: arrayUnion(newActivity)
        });
        setMessage("")
    }
    const deleteFile = async (file) => {
        await deleteObject(ref(storage, '/' + clientId + '/' + file.name))
        let newActivity = {
            id: Date.now(),
            type: 'system',
            message: 'Deleted document: ' + file.name,
            date: Date.now(),
        }
        await updateDoc(doc(db, "clients", clientId), { activity: arrayUnion(newActivity) });
    }
    if (!client) return <></>
    return (
        <>
            <main>
                <DeleteClientDialog open={deleteClientOpened} onClose={() => setDeleteClientOpened(false)} />
                <UploadDocumentsDialog open={uploadOpened} onClose={() => setUploadOpened(false)} />
                <header className="relative isolate">
                    <div className="absolute inset-0 -z-10 overflow-hidden" aria-hidden="true">
                        <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
                            <div
                                className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#E11D48] to-[#404040]"
                                style={{
                                    clipPath:
                                        'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                                }}
                            />
                        </div>
                        <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
                    </div>

                    <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 lg:px-8">
                        <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                            <div className="flex items-center gap-x-6">
                                <NavLink to={'/'} className="flex items-center gap-x-2 text-gray-800 hover:text-gray-600 ">
                                    <ArrowLongLeftIcon className="h-6 w-6 flex-none rounded-full" />
                                    <span className="font-semibold text-sm">Back</span>
                                </NavLink>
                            </div>
                            <div className="flex items-center gap-x-4 sm:gap-x-6">
                                {(auth.currentUser.uid == "AltaiGlobal" || auth.currentUser.uid == "Aixinvestment" && !client.validatedByAccuvise) &&
                                    <button onClick={() => setDeleteClientOpened(true)} type="button" className="text-sm font-semibold leading-6 text-gray-900 hover:text-gray-600 block">
                                        Delete
                                    </button>
                                }
                                {(auth.currentUser.uid == "AltaiGlobal" && client.submittedToAccuvise && !client.validatedByAccuvise) &&
                                    <span className="inline-flex items-center gap-x-2 rounded-md bg-orange-100 border border-orange-600 px-3 py-2 text-sm font-medium text-orange-700">
                                        Submitted to Accuvise
                                        <button type="button" onClick={unsubmitFromAccuvise} className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-orange-600/20">
                                            <span className="sr-only">Remove</span>
                                            <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-orange-800/50 group-hover:stroke-orange-800/75">
                                                <path d="M4 4l6 6m0-6l-6 6" />
                                            </svg>
                                            <span className="absolute -inset-1" />
                                        </button>
                                    </span>
                                }
                                {((auth.currentUser.uid == "AltaiGlobal") && !client.flaggedToAix && !client.submittedToAccuvise) &&
                                    <button
                                        onClick={flagToAix}
                                        className="rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                                    >
                                        Flag to Aix
                                    </button>
                                }
                                {(auth.currentUser.uid == "AltaiGlobal" && client.flaggedToAix && !client.submittedToAccuvise) &&
                                    <span className="inline-flex items-center gap-x-2 rounded-md bg-orange-100 border border-orange-600 px-3 py-2 text-sm font-medium text-orange-700">
                                        Flagged to Aix
                                        <button type="button" onClick={unFlagToAix} className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-orange-600/20">
                                            <span className="sr-only">Remove</span>
                                            <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-orange-800/50 group-hover:stroke-orange-800/75">
                                                <path d="M4 4l6 6m0-6l-6 6" />
                                            </svg>
                                            <span className="absolute -inset-1" />
                                        </button>
                                    </span>
                                }
                                {((auth.currentUser.uid == "AltaiGlobal" || auth.currentUser.uid == "Accuvise") && client.validatedByAccuvise) &&
                                    <span className="inline-flex items-center gap-x-2 rounded-md bg-green-100 border border-green-600 px-3 py-2 text-sm font-medium text-green-700">
                                        Validated By Accuvise
                                    </span>
                                }
                                {(auth.currentUser.uid == "Accuvise" && !client.validatedByAccuvise) &&
                                    <button
                                        onClick={validateByAccuvise}
                                        className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    >
                                        {submitByAccuviseLoading
                                            ? <>
                                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>

                                            </>
                                            : <>Validate</>
                                        }
                                    </button>
                                }


                                {(auth.currentUser.uid == "AltaiGlobal" && !client.submittedToAccuvise) &&
                                    <button
                                        onClick={submitToAccuvise}
                                        className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    >
                                        Submit to Accuvise
                                    </button>
                                }

                                {/* <a
                                    href="#"
                                    className="rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
                                >
                                    Save
                                </a> */}
                            </div>
                        </div>
                    </div>
                </header>

                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                        <div className="col-span-3 space-y-8">
                            <div className="-mx-4 px-4 pt-6 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 lg:col-span-2 lg:row-span-2 lg:row-end-2">
                                <div className="flex w-full gap-x-1.5 items-center border-b border-gray-200 pb-4">
                                    <UserCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                    <h2 className="text-sm font-semibold leading-6 text-gray-900">{client.fullName}</h2>
                                </div>
                                <dl className="mt-4 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                                    <div>
                                        <div>
                                            <label htmlFor="dateOfBirth" className="block text-sm font-medium leading-6 text-gray-900">
                                                Date of Birth
                                            </label>
                                            <div className="relative mt-2">
                                                <input
                                                    type="text"
                                                    name="dateOfBirth"
                                                    autoComplete={false}
                                                    id="dateOfBirth"
                                                    className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                                    value={client.dateOfBirth}
                                                    onChange={(evt) => setClient({ ...client, dateOfBirth: evt.target.value })}
                                                />
                                                <div
                                                    className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:pl-4">
                                        <div>
                                            <label htmlFor="placeOfBirth" className="block text-sm font-medium leading-6 text-gray-900">
                                                Place of birth
                                            </label>
                                            <div className="relative mt-2">
                                                <input
                                                    type="text"
                                                    name="placeOfBirth"
                                                    autoComplete={false}
                                                    id="placeOfBirth"
                                                    className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                                    value={client.placeOfBirth}
                                                    onChange={(evt) => setClient({ ...client, placeOfBirth: evt.target.value })}
                                                />
                                                <div
                                                    className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <div>
                                            <label htmlFor="nationality" className="block text-sm font-medium leading-6 text-gray-900">
                                                Nationality
                                            </label>
                                            <div className="relative mt-2">
                                                <input
                                                    type="text"
                                                    name="nationality"
                                                    id="nationality"
                                                    className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                                    value={client.nationality}
                                                    onChange={(evt) => setClient({ ...client, nationality: evt.target.value })}
                                                />
                                                <div
                                                    className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3 pl-4">
                                        <div>
                                            <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                                                Address
                                            </label>
                                            <div className="relative mt-2">
                                                <input
                                                    type="text"
                                                    name="address"
                                                    id="address"
                                                    className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                                    value={client.address}
                                                    onChange={(evt) => setClient({ ...client, address: evt.target.value })}
                                                />
                                                <div
                                                    className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                Email
                                            </label>
                                            <div className="relative mt-2">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    autoComplete={false}
                                                    className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                                    value={client.email}
                                                    onChange={(evt) => setClient({ ...client, email: evt.target.value })}
                                                />
                                                <div
                                                    className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 sm:pl-4">
                                        <div>
                                            <label htmlFor="passport" className="block text-sm font-medium leading-6 text-gray-900">
                                                Passport
                                            </label>
                                            <div className="relative mt-2">
                                                <input
                                                    type="text"
                                                    name="passport"
                                                    id="passport"
                                                    className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                                    value={client.passportNo}
                                                    onChange={(evt) => setClient({ ...client, passportNo: evt.target.value })}
                                                />
                                                <div
                                                    className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </dl>
                                <div className=" mt-4 flex items-center justify-end gap-x-6 border-t border-gray-900/10 py-4">
                                    <button
                                        onClick={save}
                                        className={classNames(saveText == "Successfully saved" ? "text-gray-600 border border-gray-600 bg-gray-50" : "bg-rose-600 text-white shadow-sm hover:bg-rose-500", "rounded-md px-3 py-2 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600")}
                                    >
                                        {saveText}
                                    </button>
                                </div>
                            </div>
                            <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                                <dl className="flex flex-wrap">
                                    <div className="flex-auto px-6 pt-4 pb-4 w-full rounded-t-lg bg-white border-b border-b-gray-200">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 flex justify-between">Documents
           
                                            <button type="button" onClick={downloadAllFiles} className="rounded-md  font-medium text-rose-600 hover:text-rose-700">
                                                Download All
                                            </button>
                                        </dt>
                                    </div>
                                    {loadingDocuments
                                        ? <div className="flex w-full z-40 text-center justify-center p-4">
                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </div>
                                        : <></>
                                    }
                                    {files.map(file =>
                                        <div className="mt-1 w-full flex px-6 text-sm">
                                            <div className="w-full flex items-center justify-between py-2 text-sm leading-6">
                                                <div className="flex w-0 flex-1 items-center">
                                                    <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                        <span className="truncate font-medium">{file.name}</span>
                                                        <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                            {file.size}
                                                        </span>

                                                        <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                                            Uploaded on  {file.uploadDate}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="ml-4 flex flex-shrink-0 space-x-4">
                                                    {/* onClick={() => window.open(file.downloadURL, '_blank')} */}
                                                    <button onClick={() => downloadFile(file.downloadURL, file.name)}  className="font-medium text-rose-600 hover:text-rose-500">
                                                        Download
                                                    </button>
                                                    <span className="text-gray-200" aria-hidden="true">
                                                        |
                                                    </span>
                                                    <button type="button" onClick={() => deleteFile(file)} className="rounded-md  font-medium text-gray-900 hover:text-gray-800">
                                                        Remove
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                </dl>
                                <div className="mt-3 border-t border-gray-200 px-6 py-2 text-center w-full bg-white hover:opacity-80 rounded-b-lg">
                                    <button onClick={() => setUploadOpened(true)} className="text-sm font-semibold leading-6 text-gray-800">
                                        Upload
                                    </button>
                                </div>
                            </div>
                            <div className="-mx-4 px-4 py-6 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 lg:col-span-2 lg:row-span-2 lg:row-end-2">
                                <div className="flex w-full gap-x-1.5 items-center border-b border-gray-200 pb-4">
                                    <h2 className="text-sm font-semibold leading-6 text-gray-900">Banking Details</h2>
                                </div>
                                <dl className="mt-4 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                                    <div className="sm:pr-4">
                                        <div>
                                            <label htmlFor="bankname" className="block text-sm font-medium leading-6 text-gray-900">
                                                Bank Name
                                            </label>
                                            <div className="relative mt-2">
                                                <input
                                                    type="text"
                                                    name="bankname"
                                                    autoComplete={false}
                                                    id="bankname"
                                                    className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                                    value={client.bankName}
                                                    onChange={(evt) => setClient({ ...client, bankName: evt.target.value })}
                                                />

                                                <div
                                                    className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 sm:mt-0 sm:pl-4">
                                        <div>
                                            <label htmlFor="branch" className="block text-sm font-medium leading-6 text-gray-900">
                                                Branch
                                            </label>
                                            <div className="relative mt-2">
                                                <input
                                                    type="text"
                                                    name="branch"
                                                    id="branch"
                                                    autoComplete={false}
                                                    className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                                    value={client.bankBranch}
                                                    onChange={(evt) => setClient({ ...client, bankBranch: evt.target.value })}
                                                />
                                                <div
                                                    className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div>
                                            <label htmlFor="bankcurrency" className="block text-sm font-medium leading-6 text-gray-900">
                                                Bank currency
                                            </label>
                                            <div className="relative mt-2">
                                                <input
                                                    type="text"
                                                    name="bankcurrency"
                                                    id="bankcurrency"
                                                    className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                                    value={client.bankCurrency}
                                                    onChange={(evt) => setClient({ ...client, bankCurrency: evt.target.value })}
                                                />
                                                <div
                                                    className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 sm:pl-4">
                                        <div>
                                            <label htmlFor="accountNo" className="block text-sm font-medium leading-6 text-gray-900">
                                                Account No
                                            </label>
                                            <div className="relative mt-2">
                                                <input
                                                    type="text"
                                                    name="accountNo"
                                                    id="accountNo"
                                                    className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                                    value={client.bankAccountNo}
                                                    onChange={(evt) => setClient({ ...client, bankAccountNo: evt.target.value })}
                                                />
                                                <div
                                                    className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 col-span-2">
                                        <div>
                                            <label htmlFor="iban" className="block text-sm font-medium leading-6 text-gray-900">
                                                IBAN No.
                                            </label>
                                            <div className="relative mt-2">
                                                <input
                                                    type="text"
                                                    name="iban"
                                                    id="iban"
                                                    className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                                    value={client.iban}
                                                    onChange={(evt) => setClient({ ...client, iban: evt.target.value })}
                                                />
                                                <div
                                                    className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 col-span-2">
                                        <div>
                                            <label htmlFor="iban" className="block text-sm font-medium leading-6 text-gray-900">
                                                Bic/Swift
                                            </label>
                                            <div className="relative mt-2">
                                                <input
                                                    type="text"
                                                    name="bicswift"
                                                    id="bicswift"
                                                    className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                                    value={client.bicswift}
                                                    onChange={(evt) => setClient({ ...client, bicswift: evt.target.value })}
                                                />
                                                <div
                                                    className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-gray-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </dl>
                                <div className=" mt-4 flex items-center justify-end gap-x-6 border-t border-gray-900/10 py-4">
                                    <button
                                        onClick={save}
                                        className={classNames(saveText == "Successfully saved" ? "text-gray-600 border border-gray-600 bg-gray-50" : "bg-rose-600 text-white shadow-sm hover:bg-rose-500", "rounded-md px-3 py-2 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600")}
                                    >
                                        {saveText}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-2">

                            <div className="py-6 col-span-3 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 rounded-lg px-8">

                                <h2 className="text-sm font-semibold leading-6 text-gray-900">Activity</h2>
                                <ul role="list" className="mt-6 space-y-6 h-96 overflow-y-scroll">
                                    {client.activity?.filter(activity => !activity.target || activity.target == "everyone" || activity.target == auth.currentUser.uid || activity.author == auth.currentUser.uid).map((activityItem, activityItemIdx) => (
                                        <li key={activityItem.id} className="relative flex gap-x-4">
                                            <div
                                                className={classNames(
                                                    activityItemIdx === client.activity.length - 1 ? 'h-6' : '-bottom-6',
                                                    'absolute left-0 top-0 flex w-6 justify-center'
                                                )}
                                            >
                                                <div className="w-px bg-gray-200" />
                                            </div>
                                            {activityItem.type === 'comment' ? (
                                                <>
                                                    <UserIcon
                                                        alt=""
                                                        className="relative mt-3 h-6 w-6 p-1 flex-none rounded-full text-gray-300 bg-white border border-gray-300"
                                                    />
                                                    <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                                                        <div className="flex justify-between gap-x-4">
                                                            <div className="py-0.5 text-xs leading-5 text-gray-500">
                                                                <span className="font-medium text-gray-900">{activityItem.author}</span> to {activityItem.target} : {activityItem.message}
                                                            </div>
                                                            <p
                                                                className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                                                            >
                                                                {timeAgo.format(Date.now() - (Date.now() - activityItem.date), 'round-minute')}
                                                            </p>
                                                        </div>
                                                        <p className="text-sm leading-6 text-gray-500">{activityItem.comment}</p>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">

                                                        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />

                                                    </div>
                                                    <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                        {activityItem.message}
                                                    </p>
                                                    <p
                                                        className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                                                    >
                                                        {timeAgo.format(Date.now() - (Date.now() - activityItem.date), 'round-minute')}
                                                    </p>
                                                </>
                                            )}
                                        </li>
                                    ))}
                                </ul>

                                {/* New comment form */}
                                <div className="mt-6 flex gap-x-3">
                                    <form action="#" className="relative flex-auto">
                                        <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-rose-600">
                                            <label htmlFor="comment" className="sr-only">
                                                Add your comment
                                            </label>
                                            <textarea
                                                rows={2}
                                                value={message}
                                                onChange={(evt) => setMessage(evt.target.value)}
                                                name="comment"
                                                id="comment"
                                                className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                placeholder="Add your comment..."
                                            />
                                        </div>

                                        <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                                            <div className="flex items-center space-x-5">

                                            </div>
                                            <div className="flex space-x-1">
                                                <button
                                                    type="submit"
                                                    onClick={sendComment}
                                                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                >
                                                    Comment
                                                </button>
                                                {auth.currentUser.uid == "AltaiGlobal" && <select
                                                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    value={messageTarget}
                                                    onChange={(evt) => setMessageTarget(evt.target.value)}
                                                >
                                                    <option value={"everyone"}>to Everyone</option>
                                                    <option value={"Accuvise"}>to Accuvise</option>
                                                    <option value={"Aixinvestment"}>to Aixinvestment</option>
                                                </select>}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </main>
        </>
    )
}

// <div className="lg:col-start-3 lg:row-end-1">
// <h2 className="sr-only">Summary</h2>
// <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
//     <dl className="flex flex-wrap">
//         <div className="flex-auto pl-6 pt-4 pb-4 w-full rounded-t-lg bg-white border-b border-b-gray-200">
//             <dt className="text-sm font-semibold leading-6 text-gray-900">Documents</dt>
//         </div>
//         {files.map(file =>
//             <div className="mt-1 w-full flex px-6 text-sm">
//                 <div className="w-full flex items-center justify-between py-2 text-sm leading-6">
//                     <div className="flex w-0 flex-1 items-center">
//                         <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
//                         <div className="ml-4 flex min-w-0 flex-1 gap-2">
//                             <span className="truncate font-medium">{file.name}</span>
//                             <span className="flex-shrink-0 text-gray-400">{file.size}</span>
//                         </div>
//                     </div>
//                     <div className="ml-4 flex-shrink-0">
//                         <button onClick={() => window.open(file.downloadURL, '_blank')} className="font-medium text-rose-600 hover:text-gray-500">
//                             Download
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         )}
//     </dl>
//     <div className="mt-6 border-t border-gray-200 px-6 py-2 text-center w-full bg-white hover:opacity-80 rounded-b-lg">
//         <button onClick={() => setUploadOpened(true)} className="text-sm font-semibold leading-6 text-gray-800">
//             Upload
//         </button>
//     </div>
// </div>
// </div>
