import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { functions } from '../firebase';
import { httpsCallable } from "firebase/functions";
import { db } from '../firebase';
import { deleteDoc, doc } from "firebase/firestore";

export default function ManageFa({ open, onClose }) {
  const [username, setUsername] = useState("")
  const cancelButtonRef = useRef(null)
  const [processingAdd, setProcessingAdd] = useState(false)
  const [agents, setAgents] = useState([])
  useEffect(() => {
    refreshAgents()
  }, [])
  const refreshAgents = async () => {
    const getFas = httpsCallable(functions, 'getFas');
    let res = await getFas()
    setAgents(res.data)
  }
  const addFa = async () => {
    setProcessingAdd(true)
    const add = httpsCallable(functions, 'addFa');
    await add({ name: username })
    refreshAgents()
    setProcessingAdd(false)
    setUsername("")
  }
  const onRemove = async (id) => {
    const docRef = doc(db, "financial_advisors", id);
    await deleteDoc(docRef);
    refreshAgents()
    return;
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:items-start w-full">
                  <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg font-medium text-gray-900">
                      Manage
                    </Dialog.Title>
                    <div className="mt-5 w-full ">
                      <div className="w-full grid grid-cols-3 gap-2">
                        <input
                          name="username"
                          id="username"
                          className="col-span-2 flex w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm sm:leading-6"
                          placeholder="Username"
                          value={username}
                          onChange={(evt) => {
                            const newValue = evt.target.value;
                            // Replace spaces with empty string (i.e., remove spaces)
                            const sanitizedValue = newValue.replace(/\s+/g, '');
                            setUsername(sanitizedValue);
                          }}

                        />
                        <button
                          type="button"
                          onClick={addFa}
                          className="w-full justify-center inline-flex items-center rounded-md bg-rose-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
                        >
                          {processingAdd
                            ? <>
                              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                              Adding...
                            </>
                            : <>Add</>
                          }
                        </button>
                      </div>
                      <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead>
                                <tr>
                                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    <a href="#" className="group inline-flex">
                                      Username
                                      <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    </a>
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    <a href="#" className="group inline-flex">
                                      Password
                                      <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    </a>
                                  </th>
                                  <th scope="col" className="relative py-3.5 pl-3 pr-0">
                                    <span className="sr-only">Remove</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {agents.map((agent) => (
                                  <tr key={agent.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                      {agent.username}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{agent.password}</td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0">
                                      <button onClick={() => onRemove(agent.id)} className="text-rose-600 hover:text-rose-900 font-semibold">
                                        Remove
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
