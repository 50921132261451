import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { auth } from "./firebase"
import { onAuthStateChanged } from 'firebase/auth'
import Home from "./views/home";
import Client from "./views/client";
import Login from "./views/login";

function App() {
  const [loggedIn, setLoggedIn] =  useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, async user => setLoggedIn(user ? true : false));
  }, []);
  if (loggedIn == null) return <></>
  if(!loggedIn) return <Login/>
  return (
      <Routes>
          <Route path="clients/:clientId" element={<Client/>} />
          <Route path="*" element={<Home/>} />
      </Routes>
  
  );
}

export default App;
