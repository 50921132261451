import { initializeApp } from "firebase/app"
import {getAuth} from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
const firebaseConfig = {
    apiKey: "AIzaSyCYD7GB0FDnR-X2ywrsKKb4jUZBug4uwx4",
    authDomain: "aix-onboarding.firebaseapp.com",
    projectId: "aix-onboarding",
    storageBucket: "aix-onboarding.appspot.com",
    messagingSenderId: "142170799640",
    appId: "1:142170799640:web:22fce3270176b56fd98d6d",
    measurementId: "G-W13E82YHQW"
};

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)
const db = getFirestore(firebaseApp)
const functions = getFunctions(firebaseApp);
const storage = getStorage(firebaseApp);

export { db, auth, functions, storage }