import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/20/solid'

export default function McbInformation({ open, onClose }) {


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={onClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <Dialog.Title as="h3" className="font-medium text-lg leading-6 text-gray-900">
                                            MCB Partnership
                                        </Dialog.Title>
                                        <div className="mt-3">
                                            <p className="text-md text-gray-800">
                                                At Altai Global Ltd, we are proud to collaborate with one of the largest banking groups in Africa – The Mauritius Commercial Bank (MCB), a cornerstone of stability and reliability in the financial sector since 1838. Our enduring partnership with MCB underscores our commitment to providing secure and sophisticated financial solutions to our clients.
                                            </p>
                                            <ul className="mt-3 text-md text-gray-800 list-disc space-y-3">
                                                <span className="mt-3 text-md text-gray-900 font-medium space-y-3">MCB Highlights:</span>
                                                <li>
                                                    A rich history dating back to 1838, with a robust presence in key global locations including Mauritius, France, Madagascar, Mozambique, Seychelles, Maldives, and representative offices in Johannesburg, Nairobi, Lagos, and Dubai.</li>
                                                <li>A dedicated team of 3,900 employees committed to servicing over 1 million clients, showcasing MCB's scale and capability to meet diverse banking needs.</li>
                                                <li>An impressive portfolio with total assets amounting to USD 20 billion, reflecting the bank's solid financial foundation and trustworthiness.</li>
                                                <li>Recognition as the best-rated bank in sub-Saharan Africa, with a Baa3 investment grade rating from Moody's, highlighting MCB's financial stability and creditworthiness.</li>

                                            </ul>
                                            <ul className="mt-3 text-md text-gray-800 space-y-3">
                                                <span className="mt-3 text-md text-gray-900 font-medium space-y-3">Client Account Details at MCB:</span>
                                                <li> <span className="underline">Account Number</span>: 000450453413</li>
                                                <li><span className="underline">IBAN Number</span>: MU89MCBL0944000450453413000EUR</li>
                                                <li><span className="underline">Bank Address</span>: The Mauritius Commercial Bank Ltd, Sir William Newton Street, Port-Louis, Republic of Mauritius</li>
                                                <li><span className="underline">Swift Code</span>: MCBLMUMU</li>

                                            </ul>
                                            <p className="mt-3 text-gray-800"><span className="text-gray-900 font-medium">Note:</span><span className="ml-1">In line with Mauritius financial regulations, we assure our clients that cash accounts are strictly segregated, ensuring unparalleled security and peace of mind.</span>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}
