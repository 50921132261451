import React, { useState, useEffect } from 'react'
import CreateClientForm from './clientForm'
import { db, auth } from '../firebase';
import { signOut } from "firebase/auth";
import { collection, onSnapshot } from "firebase/firestore";
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { NavLink } from 'react-router-dom';
import ManageFa from './manageFa';
import McbInformation from './mcbInformation';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Feed from './feed';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function Home() {
  const [clients, setClients] = useState([])
  const [clientFormOpened, setClientOpened] = useState(false)
  const [FaOpened, setFaOpened] = useState(false)
  const [mcbInfoOpened, setMcbInfoOpened] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');


  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "clients"), (snapshots) => {
      let clients = [];
      snapshots.forEach((doc) => clients.push({ ...doc.data(), uid: doc.id }))
      if (auth.currentUser.uid.includes('FA_')) clients = clients.filter(client => client.financialAdvisor?.username == auth.currentUser.uid);
      if (auth.currentUser.uid == "Accuvise") clients = clients.filter(client => client.submittedToAccuvise);
      setClients(clients)
    });
    return () => unsubscribe()
  }, [])
  const filteredClients = clients.filter((client) =>
    client.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    client.nationality.toLowerCase().includes(searchQuery.toLowerCase()) ||
    client.passportNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
    client.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    client.address.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div>
      <CreateClientForm open={clientFormOpened} onClose={() => setClientOpened(false)} />
      {auth.currentUser.uid == "Aixinvestment" && <ManageFa open={FaOpened} onClose={() => setFaOpened(false)} />}
      <McbInformation open={mcbInfoOpened} onClose={() => setMcbInfoOpened(false)} />
      <header className="relative isolate">
        <div className="absolute inset-0 -z-10 overflow-hidden" aria-hidden="true">
          <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
            <div
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#E11D48] to-[#404040]"
              style={{
                clipPath:
                  'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
              }}
            />
          </div>
          <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
        </div>
        <div className="mx-auto max-w-7xl px-4 py-5 sm:px-6 lg:px-8">
          <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
            <img src="https://firebasestorage.googleapis.com/v0/b/aix-onboarding.appspot.com/o/assets%2Flogo.png?alt=media&token=ea506770-5a3a-4b41-8801-59aa4b07bcc6" className="h-10 w-auto"></img>
          </div>
        </div>
      </header>
      <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 py-2.5">
        <div
          className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#60a5fa] to-[#0ea5e9] opacity-30"
            style={{
              clipPath:
                'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
            }}
          />
        </div>
        <div
          className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#60a5fa] to-[#0ea5e9] opacity-30"
            style={{
              clipPath:
                'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
            }}
          />
        </div>
        <div className="max-w-7xl mx-auto w-full flex items-center  px-4 sm:px-6 lg:px-8" >
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
            <p className="text-sm leading-6 text-gray-900">
              <strong className="font-semibold">Information</strong>
              <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                <circle cx={1} cy={1} r={1} />
              </svg>
              Discover More About Our Trusted Banking Partner MCB
            </p>
            <button
              onClick={() => setMcbInfoOpened(true)}
              className="flex-none rounded-full bg-gray-600 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            >
              Read Now <span aria-hidden="true">&rarr;</span>
            </button>
          </div>
          <div className="flex flex-1 justify-end">
            <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
            </button>
          </div>
        </div>


      </div>
      <div className={classNames(auth.currentUser.uid == "AltaiGlobal" ? "md:grid md:grid-cols-10" : "", "mx-auto max-w-7xl")}>
        <div className="md:col-span-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8">
            <div className="mt-10 sm:flex sm:items-center">
              <div className="w-full flex justify-between">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">Clients</h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the users in your account including their name, title, email and role.
                  </p>
                </div>
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 flex w-full justify-end gap-x-2">
                {(auth.currentUser.uid == "Aixinvestment" &&
                  <button
                    type="button"
                    onClick={() => setFaOpened(true)}
                    className="block rounded-md bg-white border border-gray-700 text-gray-700 px-3 py-2 text-center text-sm font-semibold shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  >
                    Manage Financial Advisors
                  </button>
                )}
                {(auth.currentUser.uid == "Aixinvestment" || auth.currentUser.uid == "AltaiGlobal") && <button
                  type="button"
                  onClick={() => setClientOpened(true)}
                  className="block rounded-md bg-rose-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
                >
                  Submit client +
                </button>}
              </div>
            </div>
            <div className="sm:flex-none">
              <div className="relative mt-4 rounded-md">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  placeholder="Search clients..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="block w-96 rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rose-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div className="mt-6 flow-root overflow-auto overflow-x-clip max-h-[500px]">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <table className="w-full text-left">
                <thead className="bg-white sticky-header">
                  <tr>
                    <th scope="col" className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                      Full Name
                      <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                      <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell border-b border-b-gray-200"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell border-b border-b-gray-200"
                    >
                      Client Email
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 border-b border-b-gray-200">
                      <span className="sr-only">Detail</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="overflow-y-auto">
                  {filteredClients.sort((a, b) => {
                    if (a.validatedByAccuvise && !b.validatedByAccuvise) {
                      return 1;
                    } else if (!a.validatedByAccuvise && b.validatedByAccuvise) {
                      return -1;
                    } else {
                      return b.uid.localeCompare(a.uid);
                    }
                  }).map((client) => (
                    <tr key={client.uid}>
                      <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                        {client.fullName}
                        <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                        <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                        {(auth.currentUser.uid == "Aixinvestment" || auth.currentUser.uid.includes("FA_")) && !client.validatedByAccuvise && !client.flaggedToAix &&
                          <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                            Submitted to Altai
                          </span>
                        }
                        {(auth.currentUser.uid == "Aixinvestment" || auth.currentUser.uid.includes("FA_")) && !client.validatedByAccuvise && !client.submittedToAccuvise && client.flaggedToAix &&
                          <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10">
                            Flagged By Altai
                          </span>
                        }
                        {auth.currentUser.uid == "AltaiGlobal" && !client.validatedByAccuvise && !client.submittedToAccuvise && client.flaggedToAix &&
                          <span className="inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-700/10">
                            Flagged
                          </span>
                        }
                        {auth.currentUser.uid == "AltaiGlobal" && !client.validatedByAccuvise && !client.submittedToAccuvise && !client.flaggedToAix &&
                          <span className="inline-flex items-center rounded-md bg-rose-50 px-2 py-1 text-xs font-medium text-rose-700 ring-1 ring-inset ring-orange-700/10">
                            To Process
                          </span>
                        }
                        {auth.currentUser.uid == "AltaiGlobal" && !client.validatedByAccuvise && client.submittedToAccuvise &&
                          <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                            Submitted to Accuvise
                          </span>
                        }
                        {auth.currentUser.uid == "Accuvise" && !client.validatedByAccuvise && client.submittedToAccuvise &&
                          <span className="inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-700/10">
                            To be processed
                          </span>
                        }
                        {client.validatedByAccuvise &&
                          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-700/10">
                            Onboarded
                          </span>
                        }
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">{client.email}</td>
                      <td className="relative py-4 pl-3 text-right text-sm font-medium">
                        <NavLink to={`clients/${client.uid}`} className="text-blue-600 hover:text-blue-900">
                          Details
                        </NavLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {auth.currentUser.uid == "AltaiGlobal" && <div className="hidden md:block mt-6 md:col-span-4 px-4 sm:px-6 lg:px-8 border-l border-gray-200">
          <Feed clients={clients} />
        </div>}
      </div>

      <footer className="mt-1 mx-auto w-full">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-12 md:flex md:items-center md:justify-between">
          <div className="flex justify-center space-x-6 md:order-2">
            {/* {navigation.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))} */}
          </div>
          <div className="mt-8 md:order-1 md:mt-0 flex justify-between w-full">
            <p className="text-center text-sm leading-7 text-gray-500">
              &copy; 2024 Altai Global, Inc. All rights reserved.
            </p>
            <button onClick={() => signOut(auth)} className="text-sm leading-7 text-gray-700 hover:text-gray-400 underline">
              Sign out
            </button>
          </div>
        </div>
      </footer>
    </div>
  )
}


