import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useParams, useNavigate } from 'react-router-dom'
import { FilePond } from "react-filepond";
import { db, storage } from '../firebase';
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { uploadBytes, ref } from "firebase/storage";
const classNames = require('classnames');

export default function UploadDocumentsDialog({ open, onClose }) {
    let { clientId } = useParams();
    const [files, setFiles] = useState([])
    const [processing, setProcessing] = useState(false)
    const uploadDocument = async (evt) => {
        evt.preventDefault()
        setProcessing(true)
    
        for (let i = 0; i < files.length; i++) {
            let file = files[i]
            await uploadBytes(ref(storage, clientId + '/' + file.name), file)
        }
        let newActivity = { 
            id: Date.now(), 
            type: 'system', 
            message: 'New documents uploaded: ' + files.map(file => file.name).join(', '), 
            date: Date.now(),
        }
        await updateDoc(doc(db, "clients", clientId), { activity: arrayUnion(newActivity) });
        setProcessing(false)
        onClose();
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">

                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Upload new document
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                               Upload new documents to attach to the client's file.
                                            </p>
                                        </div>
                                        <div className="mt-3 w-full">
                                            <FilePond
                                                className="w-full"
                                                allowMultiple={true}
                                                files={files}
                                                onupdatefiles={(fileItems) => setFiles(fileItems.map(fileItem => fileItem.file))}
                                                name="aixForm"
                                                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                            /></div>
                                    </div>
                                </div>

                                <div className="rounded-md bg-blue-50 p-4">
                                    <div className="flex">
                                        <div className="flex-1 md:flex md:justify-between">
                                            <p className="text-sm text-blue-700">Documents must be appropriately certified as true copies of the orignal documentation by a suitable person, such as an attorney, a lawyer, a notary, an actuary, an accountant or any other person holding a recognized professional qualification, a member of the judiciary or a senior civil servant.</p>
                                        </div>
                                    </div>
                                </div>



                                <div className="mt-5 sm:mt-4 w-full flex space-x-2">
                                    <button
                                        type="button"
                                        className="mt-0 w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                    onClick={uploadDocument}
                                    type="button"
                                    className={classNames(processing ? "bg-rose-400" : "bg-rose-600 hover:bg-rose-700", "w-full justify-center inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500")}
                                    >
                                    {processing
                                        ? <>
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Uploading...
                                        </>
                                        : <>Upload</>
                                    }
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
