
import { Fragment, useEffect, useState, useCallback} from 'react'
import { Dialog, Transition } from '@headlessui/react'
// import { CurrencyDollarIcon } from '@heroicons/react/solid'
import { FilePond } from "react-filepond";
import { doc, setDoc, collection, getDocs } from "firebase/firestore";
import { db, storage } from '../firebase';
import { ref, uploadBytes } from "firebase/storage";
import { InformationCircleIcon, CheckIcon } from '@heroicons/react/20/solid'
import CountrySelector from '../components/country-selector/selector';
import { COUNTRIES } from "../components/country-selector/countries";

const classNames = require('classnames');

const steps = [
  { id: '01', name: 'Client Details', description: '', href: '#', status: 'complete' },
  { id: '02', name: 'Bank details', description: '', href: '#', status: 'current' },
  { id: '03', name: 'Document Uploads', description: '', href: '#', status: 'current' },
];

export default function CreateClientForm({ open, onClose }) {
  const [currentStep, setCurrentStep] = useState(0);
  const goToNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const [client, setClient] = useState({
    fullName: "",
    nationality: "FR",
    passportNo: "",
    expiryDate: "",
    issueDate: "",
    placeOfBirth: "",
    dateOfBirth: "",
    address: "",
    phoneNumber: "",
    financialAdvisor: "",
    email:"",
  })
  const [kycAixForm, setKycAixForm] = useState([])
  const [clientDeclarationAltai, setClientDeclarationAltai] = useState([])
  const [certifiedProofOfIdentity, setCertifiedProofOfIdentity] = useState([])
  const [bankReferenceLetter, setBankReferenceLetter] = useState([])
  const [sourceOfFunds, setSourceOfFunds] = useState([])
  const [cvFiles, setCvFiles] = useState([])
  const [proofOfAddress, setProofOfAddress] = useState([])
  const [otherSupportingDocs, setOtherSupportingDocs] = useState([])
  const [processing, setProcessing] = useState(false)
  const [nationalityOpened, setNationalityOpened] = useState(false)
  const [financialAdvisors, setFinancialAdvisors] = useState([])
  const getAdvisors = useCallback(async () => {
    try {
      let snapshot = await getDocs(collection(db, "financial_advisors"));
      let res = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
    
      if (res.length > 0) {
        setClient(prevClient => ({...prevClient, financialAdvisor: res[0]}));
      }
      setFinancialAdvisors(res);
    } catch (error) {
      console.error("Failed to fetch financial advisors:", error);
    }
  }, []); 
  useEffect(() => {
    getAdvisors();
  }, [getAdvisors, open]);
  const onSubmit = async (evt) => {
    evt.preventDefault()
    if (processing) return;

    setProcessing(true)
    let uuid = Date.now().toString();

    await setDoc(doc(db, "clients", uuid), {...client, activity: [{ id: 1, type: 'system', message:'Client created.', date: Date.now() }]});

    for (let i = 0; i < kycAixForm.length; i++) {
      let file = kycAixForm[i]
      let fileExt = file.name.split('.').pop();
      await uploadBytes(ref(storage, uuid + '/aix_kyc_form_' + i + 1 + '.' + fileExt), file)
    }

    for (let i = 0; i < clientDeclarationAltai.length; i++) {
      let file = clientDeclarationAltai[i]
      let fileExt = file.name.split('.').pop();
      await uploadBytes(ref(storage, uuid + '/client_declaration_altai_' + i + 1 + '.' + fileExt), file)
    }


    for (let i = 0; i < certifiedProofOfIdentity.length; i++) {
      let file = certifiedProofOfIdentity[i]
      let fileExt = file.name.split('.').pop();
      await uploadBytes(ref(storage, uuid + '/identity_proof_' + i + 1 + '.' + fileExt), file)
    }
  
    for (let i = 0; i < cvFiles.length; i++) {
      let file = cvFiles[i]
      let fileExt = file.name.split('.').pop();
      await uploadBytes(ref(storage, uuid + '/cv_' + i + 1 + '.' + fileExt), file)
    }
    for (let i = 0; i < proofOfAddress.length; i++) {
      let file = proofOfAddress[i]
      let fileExt = file.name.split('.').pop();
      await uploadBytes(ref(storage, uuid + '/proof_of_address_' + i + 1+ '.' + fileExt), file)
    }
    for (let i = 0; i < bankReferenceLetter.length; i++) {
      let file = bankReferenceLetter[i]
      let fileExt = file.name.split('.').pop();
      await uploadBytes(ref(storage, uuid + '/bank_proof_' + i + 1 + '.' + fileExt), file)
    }
    for (let i = 0; i < sourceOfFunds.length; i++) {
      let file = sourceOfFunds[i]
      let fileExt = file.name.split('.').pop();
      await uploadBytes(ref(storage, uuid + '/source_of_funds_' + i + 1 + '.' + fileExt), file)
    }
    for (let i = 0; i < otherSupportingDocs.length; i++) {
      let file = otherSupportingDocs[i]
      let fileExt = file.name.split('.').pop();
      await uploadBytes(ref(storage, uuid + '/supporting_' + i + 1 + '.' + fileExt), file)
    }
    setClient({
      ...client,
      fullName: "",
      nationality: "FR",
      passportNo: "",
      issueDate: "",
      expiryDate: "",
      placeOfBirth: "",
      dateOfBirth: "",
      address: "",
      phoneNumber: "",
      email:"",
    })
    setProcessing(false)
    onClose()
  }

  steps.forEach((step, index) => {
    if (index < currentStep) {
      step.status = 'complete';
    } else if (index === currentStep) {
      step.status = 'current';
    } else {
      step.status = 'upcoming';
    }
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block bg-white rounded-lg px-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-4/5  sm:p-6">
              <div>
                <h2 className="text-lg font-medium text-gray-900">Create a new client</h2>
                <p className="mt-1 text-sm text-gray-500">
                  Please fill in the following form to add a new client.
                </p>
                <div className="mt-3 lg:border-b lg:border-t lg:border-gray-200">
                  <nav className="mx-auto max-w-7xl" aria-label="Progress">
                    <ol
                      role="list"
                      className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
                    >
                      {steps.map((step, stepIdx) => (
                        <li key={step.id} className="relative overflow-hidden lg:flex-1">
                          <div
                            onClick={() => setCurrentStep(stepIdx)}
                            className={classNames(
                              stepIdx === 0 ? 'rounded-t-md border-b-0' : '',
                              stepIdx === steps.length - 1 ? 'rounded-b-md border-t-0' : '',
                              ' rounded-xl overflow-hidden border border-gray-200 lg:border-0'
                            )}
                          >
                            {step.status === 'complete' ? (
                              <a href={step.href} className="group">
                                <span
                                  className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                                  aria-hidden="true"
                                />
                                <span
                                  className={classNames(
                                    stepIdx !== 0 ? 'lg:pl-9' : '',
                                    'flex items-start px-6 py-5 text-sm font-medium'
                                  )}
                                >
                                  <span className="flex-shrink-0">
                                    <span className="flex h-10 w-10 items-center justify-center rounded-full bg-rose-600">
                                      <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </span>
                                  </span>
                                  <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                                    <span className="text-sm font-medium">{step.name}</span>
                                    <span className="text-sm font-medium text-gray-500">{step.description}</span>
                                  </span>
                                </span>
                              </a>
                            ) : step.status === 'current' ? (
                              <a href={step.href} aria-current="step">
                                <span
                                  className="absolute left-0 top-0 h-full w-1 bg-rose-600 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                                  aria-hidden="true"
                                />
                                <span
                                  className={classNames(
                                    stepIdx !== 0 ? 'lg:pl-9' : '',
                                    'flex items-start px-6 py-5 text-sm font-medium'
                                  )}
                                >
                                  <span className="flex-shrink-0">
                                    <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-rose-600">
                                      <span className="text-rose-600">{step.id}</span>
                                    </span>
                                  </span>
                                  <span className="ml-4 mt-0.5 flex min-w-0 flex-col align-center">
                                    <span className="text-sm font-medium text-rose-600">{step.name}</span>
                                 
                                  </span>
                                </span>
                              </a>
                            ) : (
                              <a href={step.href} className="group">
                                <span
                                  className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                                  aria-hidden="true"
                                />
                                <span
                                  className={classNames(
                                    stepIdx !== 0 ? 'lg:pl-9' : '',
                                    'flex items-start px-6 py-5 text-sm font-medium'
                                  )}
                                >
                                  <span className="flex-shrink-0">
                                    <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                                      <span className="text-gray-500">{step.id}</span>
                                    </span>
                                  </span>
                                  <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                                    <span className="text-sm font-medium text-gray-500">{step.name}</span>
                                    <span className="text-sm font-medium text-gray-500">{step.description}</span>
                                  </span>
                                </span>
                              </a>
                            )}
                            
                            {stepIdx !== 0 ? (
                              <>
                                <div className="absolute inset-0 left-0 top-0 hidden w-3 lg:block" aria-hidden="true">
                                  <svg
                                    className="h-full w-full text-gray-300"
                                    viewBox="0 0 12 82"
                                    fill="none"
                                    preserveAspectRatio="none"
                                  >
                                    <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vectorEffect="non-scaling-stroke" />
                                  </svg>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </li>
                      ))}
                    </ol>
                  </nav>
                </div>

                <div className="mt-2 border-b border-gray-200 py-3 space-y-3">
                  <div className="space-y-12">
                    <div>
                      <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                        {currentStep === 0 && (<>
                          <div className="sm:col-span-3">
                            <label htmlFor="full-name" className="block text-sm font-medium leading-6 text-gray-900">
                              Full Name
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="full-name"
                                id="full-name"
                                placeholder='Enter Full Name'
                                value={client.fullName}
                                onChange={(evt) => setClient({ ...client, fullName: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label htmlFor="nationality" className="block text-sm font-medium leading-6 text-gray-900">
                              Nationality
                            </label>
                            <div className="mt-2">
                              <CountrySelector 
                               id={"country-selector"}
                               open={nationalityOpened}
                               onToggle={() => setNationalityOpened(!nationalityOpened)}
                               onChange={(value) => setClient({...client, nationality: value})}
                               selectedValue={COUNTRIES.find((option) => option.value === client.nationality)}
                              /> 
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                             Client Email Address
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="email"
                                id="email"
                                placeholder='Enter Client Email'
                                value={client.email}
                                onChange={(evt) => setClient({ ...client, email: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900">
                             Client Contact Phone
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="phone"
                                id="phoneNumber"
                                placeholder='Enter Client Phone Number'
                                value={client.phoneNumber}
                                onChange={(evt) => setClient({ ...client, phoneNumber: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label htmlFor="date-of-birth" className="block text-sm font-medium leading-6 text-gray-900">
                              Date of Birth
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="date-of-birth"
                                id="date-of-birth"
                                placeholder="Enter the date of birth (dd/mm/yyyy)"
                                value={client.dateOfBirth}
                                onChange={(evt) => setClient({ ...client, dateOfBirth: evt.target.value})}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label htmlFor="date-of-birth" className="block text-sm font-medium leading-6 text-gray-900">
                              Place of Birth
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="date-of-birth"
                                id="date-of-birth"
                                placeholder="Enter the place of birth"
                                value={client.placeOfBirth}
                                onChange={(evt) => setClient({ ...client, placeOfBirth: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                              Address
                            </label>
                            <div className="mt-2">
                              <input
                                id="address"
                                placeholder='Enter Address'
                                name="address"
                                value={client.address}
                                onChange={(evt) => setClient({ ...client, address: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-2">
                            <label htmlFor="passport" className="block text-sm font-medium leading-6 text-gray-900">
                              Passport No
                            </label>
                            <div className="mt-2">
                              <input
                                id="passport"
                                name="passport"
                                placeholder='Enter Passport No'
                                value={client.passportNo}
                                onChange={(evt) => setClient({ ...client, passportNo: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>                 
                          </div>
                          <div className="sm:col-span-2">
                            <label htmlFor="issueDate" className="block text-sm font-medium leading-6 text-gray-900">
                              Issue Date
                            </label>
                            <div className="mt-2">
                              <input
                                id="issueDate"
                                name="issueDate"
                                placeholder='Enter Issue Date'
                                value={client.issueDate}
                                onChange={(evt) => setClient({ ...client, issueDate: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-2">
                            <label htmlFor="expiryDate" className="block text-sm font-medium leading-6 text-gray-900">
                              Expiry Date
                            </label>
                            <div className="mt-2">
                              <input
                                id="issueDate"
                                name="issueDate"
                                placeholder='Enter Expiry Date'
                                value={client.expiryDate}
                                onChange={(evt) => setClient({ ...client, expiryDate: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900   ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label htmlFor="financialAdvisor" className="block text-sm font-medium leading-6 text-gray-900">
                              Assign to Financial Advisor
                            </label>
                            <select
                              id="financialAdvisor"
                              name="financialAdvisor"
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-rose-600 sm:text-sm sm:leading-6"
                              value={client.financialAdvisor?.id}
                              onChange={(evt) => setClient({...client, financialAdvisor: financialAdvisors.find(fa => fa.id == evt.target.value)})}
                            >
                              {financialAdvisors.map(fa => <option value={fa.id}>{fa.username}</option>)}
                            </select>
                          </div>
                        </>)}
                        {currentStep === 1 && (
                        <>
                           <div className="sm:col-span-3">
                            <label htmlFor="bank name" className="block text-sm font-medium leading-6 text-gray-900">
                           Bank Name
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="bank-name"
                                id="bank-name"
                                placeholder='Enter Bank Name'
                                value={client.bankName}
                                onChange={(evt) => setClient({ ...client, bankName: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label htmlFor="beneficiaryName" className="block text-sm font-medium leading-6 text-gray-900">
                              Beneficiary Name
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="beneficiaryName"
                                id="beneficiaryName"
                                placeholder="Enter Beneficiary Name"
                                value={client.beneficiaryName}
                                onChange={(evt) => setClient({ ...client, beneficiaryName: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label htmlFor="currency" className="block text-sm font-medium leading-6 text-gray-900">
                              Bank Currency
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="currency"
                                id="currency"
                                placeholder="Enter Bank Currency"
                                value={client.bankCurrency}
                                onChange={(evt) => setClient({ ...client, bankCurrency: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-3">
                            <label htmlFor="accountNo" className="block text-sm font-medium leading-6 text-gray-900">
                             Account No
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="accountNo"
                                id="accountNo"
                                placeholder='Enter Account No.'
                                value={client.bankAccountNo}
                                onChange={(evt) => setClient({ ...client, bankAccountNo: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <label htmlFor="iban" className="block text-sm font-medium leading-6 text-gray-900">
                              IBAN No.
                            </label>
                            <div className="mt-2">
                              <input
                                type="text"
                                name="iban"
                                id="iban"
                                placeholder="Enter iban"
                                value={client.iban}
                                onChange={(evt) => setClient({ ...client, iban: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-6">
                            <label htmlFor="bicswift" className="block text-sm font-medium leading-6 text-gray-900">
                              BIC/SWIFT
                            </label>
                            <div className="mt-2">
                              <input
                                id="address"
                                placeholder='Enter Bic or Swift'
                                name="bicswift"
                                value={client.bicswift}
                                onChange={(evt) => setClient({ ...client, bicswift: evt.target.value })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </>)}
                        {currentStep === 2 && (
                          <>
                            <div className="sm:col-span-3 mt-4">
                              <label htmlFor="aixForm" className="block text-sm font-medium leading-6 text-gray-900">
                                Kyc Form by Aix
                              </label>
                              <div className="mt-2">
                                <FilePond
                                  allowMultiple={true}
                                  allowReorder={true}
                                  files={kycAixForm}
                                  onupdatefiles={(fileItems) => setKycAixForm(fileItems.map(fileItem => fileItem.file))}
                                  name="aixForm"
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3 mt-4">
                              <label htmlFor="aixForm" className="block text-sm font-medium leading-6 text-gray-900">
                                Client Declaration For Altai
                              </label>
                              <div className="mt-2">
                                <FilePond
                                  allowMultiple={true}
                                  allowReorder={true}
                                  files={clientDeclarationAltai}
                                  onupdatefiles={(fileItems) => setClientDeclarationAltai(fileItems.map(fileItem => fileItem.file))}
                                  name="clientDeclarationAltai"
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label htmlFor="certifiedProofOfIdentity" className="block text-sm font-medium leading-6 text-gray-900">
                                Certified Proof of Identity
                              </label>
                              <div className="mt-2">
                                <FilePond
                                  allowMultiple={true}
                                  allowReorder={true}
                                  files={certifiedProofOfIdentity}
                                  onupdatefiles={(fileItems) => setCertifiedProofOfIdentity(fileItems.map(fileItem => fileItem.file))}
                                  name="certifiedProofOfIdentity"
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label htmlFor="cv" className="block text-sm font-medium leading-6 text-gray-900">
                                CV
                              </label>
                              <div className="mt-2">
                                <FilePond
                                  allowMultiple={true}
                                  allowReorder={true}
                                  files={cvFiles}
                                  onupdatefiles={(fileItems) => setCvFiles(fileItems.map(fileItem => fileItem.file))}
                                  name="cv"
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label htmlFor="proofOfAddress" className="block text-sm font-medium leading-6 text-gray-900">
                               Proof of address
                              </label>
                              <div className="mt-2">
                                <FilePond
                                  allowMultiple={true}
                                  allowReorder={true}
                                  files={proofOfAddress}
                                  onupdatefiles={(fileItems) => setProofOfAddress(fileItems.map(fileItem => fileItem.file))}
                                  name="proofOfAddress"
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label htmlFor="bankReferenceLetter" className="block text-sm font-medium leading-6 text-gray-900">
                                Bank Reference Letter / Latest Bank Statement
                              </label>
                              <div className="mt-2">
                                <FilePond
                                  allowMultiple={true}
                                  allowReorder={true}
                                  files={bankReferenceLetter}
                                  onupdatefiles={(fileItems) => setBankReferenceLetter(fileItems.map(fileItem => fileItem.file))}
                                  name="bankReferenceLetter"
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label htmlFor="sourceOfFunds" className="block text-sm font-medium leading-6 text-gray-900">
                                Source of Funds
                              </label>
                              <div className="mt-2">
                                <FilePond
                                  id="sourceOfFunds"
                                  allowMultiple={true}
                                  allowReorder={true}
                                  files={sourceOfFunds}
                                  onupdatefiles={(fileItems) => setSourceOfFunds(fileItems.map(fileItem => fileItem.file))}
                                  name="sourceOfFunds"
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label htmlFor="otherSupportingDocs" className="block text-sm font-medium leading-6 text-gray-900">
                                Other Supporting Documents
                              </label>
                              <div className="mt-2">
                                <FilePond
                                  id="otherSupportingDocs"
                                  files={otherSupportingDocs}
                                  onupdatefiles={(fileItems) => setOtherSupportingDocs(fileItems.map(fileItem => fileItem.file))}
                                  name="otherSupportingDocs"
                                  allowMultiple={true}
                                  allowReorder={true}
                                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                />
                              </div>
                            </div>
                          </>)}
                      </div>
                    </div>

                    <div className="space-y-3">
                      {currentStep === 2 && <div className="rounded-md bg-blue-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                          </div>
                          <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">Documents must be appropriately certified as true copies of the orignal documentation by a suitable person, such as an attorney, a lawyer, a notary, an actuary, an accountant or any other person holding a recognized professional qualification, a member of the judiciary or a senior civil servant.</p>
                          </div>
                        </div>
                      </div>}
                      <div className="rounded-md bg-blue-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                          </div>
                          <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">Based on the review of documents and information submitted, we may revert for further details and/or additional documentation/information.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex space-x-2">
                  {currentStep > 0 && (
                    <button
                      type="button"
                      onClick={goToPreviousStep}
                      className="w-full rounded-md bg-gray-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    >
                      &larr; Back
                    </button>
                  )}
                  {currentStep < steps.length - 1 && (
                    <button
                      type="button"
                      onClick={goToNextStep}
                      className="w-full rounded-md bg-gray-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    >
                      Next  &rarr;
                    </button>
                  )}
                  {currentStep === steps.length - 1 && (
                    <button
                      onClick={onSubmit}
                      type="button"
                      className={classNames(processing ? "bg-rose-400" : "bg-rose-600 hover:bg-rose-700", "w-full justify-center inline-flex items-center px-4 py-2 border border-transparent   text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500")}
                    >
                      {/* <CurrencyDollarIcon className="mr-2 -ml-1 h-5 w-5" aria-hidden="true" /> */}
                      {processing
                        ? <>
                          <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Processing...
                        </>
                        : <>Create</>
                      }
                    </button>
                  )}


                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
