import { Fragment, useState, useEffect } from 'react'
import { UserIcon } from '@heroicons/react/20/solid'
import { NavLink } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Feed({ clients }) {
  const [activities, setActivities] = useState([])
  useEffect(() => {
    let nwActivities = clients
                            .map(client => client.activity.map(act => ({...act, clientId: client.uid, clientName: client.fullName})))
                            .reduce((acc, current) => acc.concat(current), [])
                            .sort((a, b) => b.date - a.date);
    setActivities(nwActivities)
  }, [clients])
  return (
    <div className="flow-root">
      <h1 class="text-base font-semibold leading-6 text-gray-900">Activity Overview</h1>
      <ul role="list" className="mt-6 -mb-8 space-y-6 overflow-auto overflow-x-clip max-h-[600px]">
        {activities.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id} className="relative flex gap-x-4 gap">
            <div
              className={classNames(
                activityItemIdx === activities.length - 1 ? 'h-6' : '-bottom-6',
                'absolute left-0 top-0 flex w-6 justify-center'
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
            {activityItem.type === 'comment' ? (
              <>
                <UserIcon
                  alt=""
                  className="relative mt-3 h-6 w-6 p-1 flex-none rounded-full text-gray-300 bg-white border border-gray-300"
                />
                
                <div className="flex-auto">
                  <div className="py-0.5 text-xs">
                      <NavLink to={`clients/${activityItem.clientId}`} className="text-blue-600 hover:text-blue-900">
                          {activityItem.clientName}
                      </NavLink>
                    </div>
                  <div className=" rounded-md p-3 ring-1 ring-inset ring-gray-200">
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-600">
                      <span className="font-medium text-gray-700">{activityItem.author}</span> to {activityItem.target} : {activityItem.message}
                    </div>
                    <p
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      {timeAgo.format(Date.now() - (Date.now() - activityItem.date), 'round-minute')}
                    </p>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">{activityItem.comment}</p>
                  </div>

                </div>
              </>
            ) : (
              <>
              
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                </div>
                <div className="flex-auto">
                <div className="py-0.5 text-xs">
                      <NavLink to={`clients/${activityItem.clientId}`} className="text-blue-600 hover:text-blue-900">
                          {activityItem.clientName}
                      </NavLink>
                      <p className=" py-0.5 text-md leading-5 text-gray-600">
                  {activityItem.message}
                </p>
                </div>
                </div>

                <p className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                  {timeAgo.format(Date.now() - (Date.now() - activityItem.date), 'round-minute')}
                </p>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}
